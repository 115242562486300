<template>
    <div id="app">
        <NavView />

        <router-view />
    </div>
</template>

<script>
    import NavView from './partials/Nav.vue'
    export default {
        name: 'App',
        components: {
            NavView
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    nav {
        padding: 30px;

        a {
            font-weight: bold;
            color: #7A55F3;

            &.router-link-exact-active {
                color: #7A55F3;
            }
        }
    }
</style>

<style>
    @import './assets/mobile.css';
</style>
