<template>
    <div>
        <div v-if="classes == null">
            <h2>Geen lessen vandaag</h2>
            <p class="small">So empty</p>
        </div>
        <div v-else>
            <h2>Lessen van vandaag:</h2>
            <b-table :items="classes" :fields="fields" thead-class="hidden_header" fixed responsive="md" class="custom-table" stacked="sm">
                <template #cell(show)="row">
                    <b-button size="sm" @click="row.toggleDetails();getPresence(row.item.id)" class="mr-2">
                        {{ row.detailsShowing ? 'Sluit' : 'Open'}} aanwezigheid
                    </b-button>
                </template>
                <template #row-details="row">
                    <b-card>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Aantal personen:</b></b-col>
                            <b-col><b-form-input v-model="classPresence.presence" type="number"></b-form-input></b-col>
                        </b-row>
                        <b-button size="sm" @click="row.toggleDetails(); saveFunction(row.item.id)" class="btn btn-secondary">Opslaan</b-button>
                    </b-card>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'ClassOverview',
        data() {
            return {
                classes: this.Classes,
                fields: ['name', 'time', 'instructor', 'show'],
                classPresence: {
                    presence: 0,
                    id: null,
                }
            }
        },
        props: {
            Classes: {
                type: Array,
            },
        },
        methods: {
            saveFunction(id) {
                this.classPresence.id = id
                axios({
                    url: 'https://teambm.azurewebsites.net/class/Post',
                    method: 'post',
                    data: this.classPresence
                })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .finally(function () {
                        this.classPresence.presence = 0
                        console.log("succes")
                    });
                this.classPresence.presence = 0
            },

            getPresence(id) {
                this.classPresence.presence =  this.classes.filter(item => item.id === id)[0].presence;
            },
        }
    }
</script>

<style scoped>
    h2 {
        text-align: left;
        padding: 20px 0px 0px 10px
    }

    table{
        color: white !important;
        margin-top: 3vh;
    }

    .small{
        font-size: 6px;
        text-align: left; 
        margin-left: 20px; 
        margin-top: -15px;
    }
</style>

<style>
    .custom-table thead th {
        color: white !important;
    }

    .custom-table tbody td {
        color: white !important;
    }
</style>

<style>
    .hidden_header {
        display: none;
    }

    @media only screen and (max-width: 600px) {
        h2 {
            text-align: left;
            padding: 10px 0px 0px 10px
        }

        table {
            margin-top: 3vh;
        }

        .custom-table {
         text-align: left;   
        }
        hr{
            border-width: 5px;
        }
    }
</style>