<template>
    <div>
        <b-col>
            <h3 style="margin-top: 1vh;">Shiftklaar:</h3>
            <div class="px-3 py-2 detail" v-if="this.details">
                <label><strong>Geschreven door:</strong></label>
                <p>{{details[0].name}}</p>
                <label class="context"><strong>Datum:</strong></label>
                <p>{{details[0].date}}</p>
                <label><strong>Shift tijd:</strong></label>
                <p>{{details[0].startTime}} - {{details[0].endTime}}</p>
                <br />
                <label><strong>Opmerkingen:</strong></label>
                <p>{{details[0].context}}</p>
            </div>

            <div v-if="!this.details">
                Geen geschreven op deze dag.
                <p>Werkt sws nog niet :(</p>
            </div>
        </b-col>
    </div>
</template>

<script>
    //import axios from 'axios'
    export default {
        name: 'AdminReport',
        data() {
            return {
                details: null,
            }
        },
        props: {
            reports: {
                type: Array,
            },
        },
        mounted() {
            //axios
            //    .get('https://teambm.azurewebsites.net/report/getdetails?id=' + this.reports[0].id)
            //    .then(response => {
            //        this.details = response.data
            //    })
        }
    }
</script>

<style scoped>
    h3 {
        text-align: left;
        padding: 1vh 0px 0px 10px
    }

    .detail {
        text-align: left;
    }

    .context {
        margin-top: 5px
    }
</style>