<template>
    <div>
        <b-container fluid>
            <b-row class="boxGroup">
                <b-col class="box" >
                    <ClassOverview :Classes="Classes"/>
                </b-col>
                <b-col class="box">
                    <ActivityView :Activity="Activity" :CompletedActivity="CompletedActivity"/>
                </b-col>
            </b-row>
        </b-container>
        
    </div>
</template>

<script>
    import ClassOverview from './ClassOverview.vue'
    import ActivityView from './ActivityView.vue'
    export default {
        name: 'BottomOverview',
        components: {
            ClassOverview,
            ActivityView
        },
        props: {
            Classes: {
                type: Array,
            },
            Activity: {
                type: Array,
            },
            CompletedActivity: {
                type: Array,
            },
        }
    }
</script>

<style scoped>
    .boxGroup {
        max-width: 87vw;
        margin-left: auto;
        margin-right: auto;
    }

    .box {
        margin-top: 5vh;
        background: rgb(71,43,162);
        background: linear-gradient(0deg, rgba(71,43,162,1) 0%, rgba(122,85,242,1) 100%);
        color: white;
        border-radius: 10px;
        margin: 25px;
    }

    h2 {
        text-align: left;
        padding: 20px 0px 0px 10px
    }

    @media only screen and (max-width: 600px) {
        .boxGroup {
            max-width: 90vw;
        }

        .box {
            margin-top: 2vh !important;
            margin-left: 0vh;
            margin: 0;
        }
    }
</style>