<template :key="key">
    <div>
        <h1 class="title">Shiftklaar</h1>
        <b-container class="box" fluid="md">
            <b-row>
                <b-col>
                    <label for="datepicker" style="margin-top: 50px;">Shift datum:</label>
                    <b-form-datepicker id="datepicker" v-model="date" class="mb-2"></b-form-datepicker>
                    <br />
                    <b-row>
                        <b-col>
                            <label for="starttime" style="margin-top: 20px;">Start tijd:</label>
                            <b-form-input id="starttime" v-model="start" class="mb-2" type="time"></b-form-input>
                        </b-col>
                        <b-col>
                            <label for="endtime" style="margin-top: 20px;">Eind tijd:</label>
                            <b-form-input id="endtime" v-model="end" class="mb-2" type="time"></b-form-input>
                        </b-col>
                    </b-row>
                    <br />
                    <b-form-textarea id="context"
                                     v-model="context"
                                     placeholder="Bijzonderheden en/of afspraken details"
                                     rows="5"
                                     max-rows="7"
                                     class="area"></b-form-textarea>
                    <b-button variant="outline-light" @click="save">Bevestig</b-button>
                </b-col>

                <b-col>
                    <label style="margin-top: 50px;">Vorige shifts:</label>
                    <b-list-group class="list">
                        <b-list-group-item class="shift" v-for="report in reports" :key="report.id" v-b-toggle.sidebar-right @click="getdetails(report.id)">
                            <h5>{{report.name}}</h5>
                            <p class="context">{{report.startTime}} - {{report.endTime}} | {{report.date}}</p>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>


        </b-container>

        <b-sidebar id="sidebar-right" title="Details" right shadow>
            <div class="px-3 py-2 detail" v-if="this.details != null" >
                <label style="margin-top: 50px;"><strong>Dienst gewerkt door:</strong></label>
                <h4>{{details[0].name}}</h4>
                <label style="margin-top: 50px;"><strong>Shift datum:</strong></label>
                <h4>{{details[0].date}}</h4>
                <label><strong>Shift tijd:</strong></label>
                <h4>{{details[0].startTime}} - {{details[0].endTime}}</h4>
                <br />
                <label><strong>Opmerkingen:</strong></label>
                <p>{{details[0].context}}</p>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'ShiftReport',
        data() {
            return {
                date: null,
                start: null,
                end: null,
                context: '',
                key: 0,
                reports: [],
                details: null,
            }
        },
        mounted() {
            axios
                .get('https://bmbackend.azurewebsites.net/report/get')
                .then(response => {
                    this.reports = response.data
                })
                .catch(function (error) {
                    console.log(error)
                });
        },

        methods: {
            getdetails(id) {
                axios
                    .get('https://teambm.azurewebsites.net/report/getdetails?id=' + id)
                    .then(response => {
                        this.details = response.data
                    })
            },

            async save() {
                await axios
                    .get('https://teambm.azurewebsites.net/report/save?name=' + localStorage.getItem("name") + '&date=' + this.date + '&start=' + this.start + '&end=' + this.end + '&context=' + this.context)
                    .then(response => {
                        this.details = response.data
                    })
                this.$router.go(0);
            }
        }
    }
</script>

<style scoped>
    .box {
        text-align: left;
        background: rgb(71,43,162);
        background: linear-gradient(0deg, rgba(71,43,162,1) 0%, rgba(122,85,242,1) 100%);
        border-radius: 10px;
        color: white;
        max-height: 40% !important;
    }
    .title {
        text-align: left;
        margin-left: 25vh;
        margin-top: 5vh;
    }

    .area{
        margin-bottom: 50px !important;
    }

    .btn{
        margin-top: -40px;
    }

    .shift {
        transition: all .2s ease-in-out;
    }

    .shift:hover {
        transform: scale(1.05);
    }

    .list{
        color: black;
    }

    .detail{
        text-align: left;
    }

    .context{
        margin-left: 40px;
    }

    @media only screen and (max-width: 600px) {
        .box {
           margin-top: 5vh;
        }

        .title {
            padding: 20px 0 0 0;
            margin-left: 2vh;
            margin-top: 0vh;
        }

        .context {
            margin-left: 10px;
        }
    }
</style>