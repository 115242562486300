<template>
    <div>
        <h4>Administratie:</h4>
        <h2>{{count}} acties</h2>
        <router-link to="/administration"><h5 style="margin-top: 35px;">Zie activiteiten</h5></router-link>
    </div>
</template>

<script>
    export default {
        name: 'AdministrationOverview',
        props: {
            Administration: {
                type: Array,
            },
        },
        data() {
            return {
                count: null,
            }
        },
        mounted() {
            this.Administration.forEach(item => {
                this.count += parseInt(item.amount);
            });
        }
    }
</script>

<style scoped>
    h4 {
        text-align: left;
        padding: 20px 0px 0px 10px
    }

    h2 {
        text-align: left;
        padding: 0px 0px 10px 60px;
    }

    h5 {
        text-align: left;
        padding: 10px 0px 0px 10px;
        text-decoration: underline;
        color: white;
    }

        h5:hover {
            color: lightslategray !important;
        }
</style>