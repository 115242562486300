<template>
    <div>
        <b-container fluid>
            <b-row class="boxGroup">
                <b-col class="box">
                   <AdminTask :taskCompleted="taskCompleted" :taskUncompleted="taskUncompleted"/>
                </b-col>
                <b-col class="box">
                    <AdminAdministration :administrations="administrations"/>
                </b-col>
                <b-col class="box">
                    <AdminPresence :presences="presences"/>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import AdminTask from './AdminTask.vue'
    import AdminAdministration from './AdminAdministration.vue'
    import AdminPresence from './AdminPresence.vue'

    export default {
        name: 'AdminTop',
        components: {
            AdminTask,
            AdminAdministration,
            AdminPresence,
        },
        data() {
            return {
            }
        },
        props: {
            taskCompleted: {
                type: Array,
            },
            taskUncompleted: {
                type: Array,
            },
            administrations: {
                type: Array,
            },
            presences: {
                type: Array,
            },
        },
    }
</script>

<style scoped>
    .boxGroup {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: flex; /* Display boxes in a row on desktop */
        flex-wrap: wrap; /* Wrap boxes to the next line on smaller screens */
        justify-content: center; /* Center boxes horizontally */
    }

    .box {
        flex: 1; /* Distribute available space evenly among boxes */
        margin-top: 10vh;
        background: rgb(94,58,212);
        background: linear-gradient(90deg, rgba(94,58,212,1) 0%, rgba(122,85,242,1) 100%);
        color: white;
        min-height: 200px;
        max-height: 400px;
        overflow-y: auto;
        margin: 25px;
        border-radius: 10px;
    }

    h4 {
        text-align: left;
        padding: 20px 0px 0px 10px
    }

    h3 {
        text-align: left;
        padding: 5px 10px 0px 60px
    }

    .button {
        max-height: 40px;
        width: 40px;
        font: bold 20px;
        margin-right: 5px;
    }

    h2 {
        text-align: left;
        padding: 0px 0px 10px 60px;
    }

    h5 {
        text-align: left;
        padding: 10px 0px 0px 10px;
        text-decoration: underline;
        color: white;
    }

        h5:hover {
            color: lightslategray !important;
        }

    .numbers {
        margin-right: -6vh;
    }

    .plus {
        position: absolute;
        top: 40%;
        left: 60%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    @media only screen and (max-width: 600px) {
        h2 {
            padding: 0px 0px 10px 20px;
        }

        h3 {
            padding: 5px 10px 0px 50px
        }

        h4 {
            padding: 20px 0px 0px 10px
        }

        .boxGroup {
            max-width: 95vw !important;
        }

        .box {
            margin-top: 1vw;
            flex: 0 0 100%; /* Full width on mobile, don't grow or shrink */
            margin-top: 2vh;
            margin-bottom: 15px;
        }
    }
</style>