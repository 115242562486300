<template>
    <div>
        <h3>Administratie:</h3>
        <b-container fluid>
            <b-form>
                <b-row>
                    <b-input-group :prepend="admin.context" class="mt-3" v-for="admin in administrations" :key="admin.id">
                        <b-form-input readonly type="number" v-model="admin.amount"></b-form-input>
                    </b-input-group>
                </b-row>
            </b-form>
        </b-container>
</div>
</template>

<script>
    export default {
        name: 'AdminAdministation',
        components: {
        },
        data() {
            return {
            }
        },
        props: {
            administrations: {
                type: Array,
            },
        }
    }
</script>

<style scoped>
    h3 {
        text-align: left;
        padding: 1vh 0px 0px 10px
    }
</style>