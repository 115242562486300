<template>
    <div>
        <b-container fluid>
            <b-row class="boxGroup">
                <b-col class="box">
                    <h4>Nog te doen:</h4>
                    <h2>{{task.completed}}/{{task.total}} taken</h2>
                    <b-progress :value="task.count" class="mb-3" height="7px" variant="success"></b-progress>
                    <router-link to="/tasks"><h5>Zie taken</h5></router-link>
                </b-col>
                <b-col class="box">
                    <h4>Nog te doen:</h4>
                    <h2>{{clean.completed}}/{{clean.total}} schoonmaak</h2>
                    <b-progress :value="clean.count" class="mb-3" height="7px" variant="success"></b-progress>
                    <router-link to="/clean"><h5>Zie schoonmaak taken</h5></router-link>
                </b-col>
                <b-col class="box presence">
                        <h4 class="plus"><router-link to="/presence" class="hover">Aanwezigheid doorgeven</router-link></h4>
                </b-col>
                <b-col class="box">
                    <AdministrationOverview :Administration="Administration"/>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import AdministrationOverview from './AdministrationOverview.vue'
    export default {
        name: 'TasksOverview',
        data() {
            return {
                tasks: 0,
                clean: {
                    total: this.CleanCompleted.length + this.CleanUncompleted.length + this.CleanImportantCompleted.length + this.CleanImportantUncompleted.length,
                    completed: (this.CleanCompleted.length + this.CleanUncompleted.length + this.CleanImportantCompleted.length + this.CleanImportantUncompleted.length) - (this.CleanCompleted.length + this.CleanImportantCompleted.length),
                    count: (this.CleanCompleted.length + this.CleanImportantCompleted.length) - (this.CleanCompleted.length + this.CleanUncompleted.length + this.CleanImportantCompleted.length + this.CleanImportantUncompleted.length)
                },
                task: {
                    total: this.TaskUncompleted.length + this.TaskCompleted.length,
                    completed: (this.TaskUncompleted.length + this.TaskCompleted.length) - this.TaskCompleted.length,
                    count: this.TaskCompleted.length - (this.TaskUncompleted.length + this.TaskCompleted.length)
                }
            }
        },
        components: {
            AdministrationOverview
        },
        props: {
            TaskCompleted: {
                type: Array,
            },
            TaskUncompleted: {
                type: Array,
            },
            CleanCompleted: {
                type: Array,
            },
            CleanUncompleted: {
                type: Array,
            },
            CleanImportantCompleted: {
                type: Array,
            },
            CleanImportantUncompleted: {
                type: Array,
            },
            Administration: {
                type: Array,
            },
            Presence: {
                type: Array,
            },
        }
    }
</script>

<style scoped>
    .boxGroup {
        max-width: 88.5%;
        margin-left: auto;
        margin-right: auto;
    }

    .box {
        margin-top: 10vh;
        background: rgb(94,58,212);
        background: linear-gradient(90deg, rgba(94,58,212,1) 0%, rgba(122,85,242,1) 100%);
        color: white;
        height: 200px;
        margin: 25px;
        border-radius: 10px;
    }

    h4{
        text-align: left;
        padding: 20px 0px 0px 10px
    }

    h3 {
        text-align: left;
        padding: 5px 10px 0px 60px
    }

    .button{
        max-height: 40px;
        width: 40px;
        font: bold 20px;
        margin-right: 5px;
    }

    h2{
        text-align:left;
        padding: 0px 0px 10px 60px;
    }

    h5{
        text-align:left;
        padding: 10px 0px 0px 10px;
        text-decoration: underline;
        color: white;
    }

    h5:hover{
        color: lightslategray !important;
    }

    .numbers {
        margin-right: -6vh;
    }

    .plus {
       text-align: center;
       margin-top: 6vh;
    }
    @media only screen and (max-width: 600px) {
        h2 {
            padding: 0px 0px 10px 20px;
        }
        h3 {
            padding: 5px 10px 0px 50px
        }
        h4 {
            padding: 20px 0px 0px 10px
        }

        .boxGroup {
            max-width: 95vw !important;
        }
        .box {
            margin-top: 5vh;
            height: 200px;
            margin-bottom: 15px !important;
            margin: 0;
            margin-top: 1vw;
        }
        .presence{
            max-height: 100px;
        }
        .plus {
            margin-top: 2vh;
        } 
    }

</style>

<style lang="css">
    .hover {
        --c: linear-gradient(lightgray 0 0); /* update the color here */
        color: white;
        padding-bottom: .15em;
        background: var(--c), var(--c);
        background-size: .3em .1em;
        background-position: 50% 100%;
        background-repeat: no-repeat;
        transition: .2s linear, background-size .2s .2s linear;
    }

        .hover:hover {
            background-size: 40% .1em;
            background-position: 10% 100%, 90% 100%;
            color: lightgray;
            text-decoration: none;
        }
</style>