<template>
    <div>
        <div v-for="user in users" :key="user.id">
            <h1 class="title">Account</h1>
            <hr />
            <br />
            <h3>Al uw gegevens:</h3>
            <p>Naam: {{user.username}}</p>
            <p>Wachtwoord: </p>
            <b-form-input id="input" :placeholder="user.password" v-model="users.password"></b-form-input>
        </div>

        <b-button @click="$router.back()">Terug</b-button>
        <b-button variant="danger" @click="change">Wachtwoord wijzigen</b-button>
    </div>
</template>

<script>  
    import axios from 'axios'

    export default {
        name: 'AccountView',
        data() {
            return {
                id: localStorage.getItem("id"),
                users: [],
            }
        },
        mounted() {
            axios
                .get('https://teambm.azurewebsites.net/user/get?id=' + this.id)
                .then(response => {
                    this.users = response.data
                    console.log(response.data)
                })
        },

        methods: {
            change() {
                axios
                    .get('https://teambm.azurewebsites.net/user/change?id=' + JSON.parse(this.id) + '&password=' + this.users.password)
                    .then(response => {
                        console.log(response.data)
                    })
            }
        }
    }
</script>

<style scoped>
    div{
        text-align: left;
        margin-left: 15vh;
    }

    container{
        text-align: left;
    }

    button{
        margin-left: 15vh;
        margin-top: 5vh;
    }
    .title {
        margin-top: 5vh;
        margin-left: 15vh;
    }

    @media only screen and (max-width: 600px) {
        div{
            margin-left: 1vh;
        }
        button {
            margin-left: 2vh;
        }
        .title {
            margin-left: 3vh;
            margin-top: 1vh;
        }
    }
</style>