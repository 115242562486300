<template>
    <div>
        <h1 class="title">{{greeting}} {{name}}</h1>

        <div class="sk-circle" v-if="!checkEmpty">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
        <div v-if="checkEmpty">       <TasksOverview :TaskCompleted="TaskCompleted" :TaskUncompleted="TaskUncompleted" :CleanCompleted="CleanCompleted" :CleanUncompleted="CleanUncompleted" :CleanImportantCompleted="CleanImportantCompleted" :CleanImportantUncompleted="CleanImportantUncompleted" :Administration="Administration" :Presence="Presence" />
            <BottomOverview :Classes="Classes" :Activity="Activity" :CompletedActivity="CompletedActivity" />
        </div>
        
    </div>
</template>

<script type="module">
    import axios from 'axios'
    import TasksOverview from '../partials/home/TasksOverview.vue'
    import BottomOverview from '../partials/home/BottomOverview.vue'
    export default {
      name: 'HomeView',
        components: {
            TasksOverview,
            BottomOverview
        },
        data() {
            return {
                name: localStorage.getItem("name"),
                checkEmpty: null,

                TaskCompleted: null,
                TaskUncompleted: null,
                CleanCompleted: null,
                CleanUncompleted: null,
                CleanImportantCompleted: null,
                CleanImportantUncompleted: null,
                Administration: null,
                Classes: null,
                Activity: null,
                CompletedActivity: null,
                Presence: null,
                greeting: '',

                currentDay: '',
                currentDate: '',
                currentTime: ''
            }
        },
        mounted() {
            this.getCurrentDayAndTime();
            axios
                .get('https://teambm.azurewebsites.net/team/Home?date=' + this.currentDate + '&time=' + this.currentTime + '&day=' + this.currentDay)
                .then(response => {
                    this.checkEmpty = response.data
                    console.log(response.data)
                    this.TaskCompleted = response.data.taskCompleted
                    this.TaskUncompleted = response.data.taskUncompleted
                    this.CleanCompleted = response.data.cleanCompleted
                    this.CleanUncompleted = response.data.cleanUncompleted
                    this.CleanImportantCompleted = response.data.cleanImportantCompleted
                    this.CleanImportantUncompleted = response.data.cleanImportantUncompleted
                    this.Administration = response.data.administration
                    this.Classes = response.data.classes
                    this.Activity = response.data.activity
                    this.CompletedActivity = response.data.completedActivity
                    this.Presence = response.data.presence
                })
        },
        methods: {
            getCurrentDayAndTime() {
                const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                const day = currentDate.getDate().toString().padStart(2, '0');

                this.currentDate = `${year}-${month}-${day}`;

                const dayIndex = currentDate.getDay();
                this.currentDay = daysOfWeek[dayIndex];

                const hours = currentDate.getHours();
                this.currentTime = hours < 10 ? `0${hours}` : hours.toString();
                this.getGreeting(this.currentTime);
            },
            getGreeting(time) {
                if (time >= 6 && time <= 12) {
                    this.greeting = 'Goedemorgen '
                }
                if (time >= 12 && time <= 18) {
                    this.greeting = 'Goedemiddag '
                }
                if (time >= 18 && time <= 24) {
                    this.greeting = 'Goedenavond '
                }
            }
        }
    }
</script>

<style scoped>
    .title{
        text-align: left;
        margin-left: 25vh;
        margin-top: 5vh;
    }

    @media only screen and (max-width: 600px) {
        .title {
            padding: 60px 0 0 0;
            margin-left: 2vh;
        }
    }
    @import '../assets/loading.css';
</style>
