<template>
    <div>
        <h2 class="title">Activiteit aanmaken</h2>
        <br />
        <b-row>
            <b-col cols="7">
                <b-form @reset="onReset" class="form" @submit.prevent>
                    <b-form-group id="input-group-1" label="Je naam:" label-for="input-1">
                        <b-form-input id="input-1"
                                      v-model="form.name"
                                      placeholder="Vul je naam in"
                                      required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-2" label="Activiteit:" label-for="input-2">
                        <b-form-select id="input-2"
                                       v-model="form.activity"
                                       :options="activities"
                                       required></b-form-select>
                    </b-form-group>

                    <b-button @click="onSubmit" class="btn">Opslaan</b-button>
                    <b-button type="reset" variant="danger" class="reset btn">Reset</b-button>
                </b-form>
            </b-col>

            <b-col class="count">
                <p v-for="activity in completed" :key="activity.name"><b>{{activity.name}}:</b> {{activity.points}} punten</p>
            </b-col>
        </b-row>
        
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'ActivityView',
        data() {
            return {
                form: {
                    name: '',
                    activity: null,
                },
                activities: this.Activity,
                completed: this.CompletedActivity,
            }
        },
        props: {
            Activity: {
                type: Array,
            },
            CompletedActivity: {
                type: Array,
            },
        },
        methods: {
            onSubmit: async function() {
                 await axios
                     .get('https://teambm.azurewebsites.net/sheet/save?name=' + this.form.name + '&task=' + this.form.activity)
                    .then(response => {
                        console.log(response.data)
                    })
            },
            onReset(event) {
                event.preventDefault()
                this.form.name = ''
                this.form.activity = null
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            }
        }
    }
</script>

<style scoped>
    .title {
        text-align: left;
        padding: 20px 0px 0px 0px;
        margin-left: 2vh;
    }

    .form {
        max-width: 90%;
        text-align: left;
    }

    .count{
        justify-content: left;
        text-align: left;
    }
     .reset{
         margin-left: 2vh;
     }

     .btn{
         margin-bottom: 10px !important;
     }
    @media only screen and (max-width: 600px) {
        .title {
            padding: 2vh 0 0 0;
            margin-left: 1vh;
        }

        .reset{
            margin-top: 5px;
            margin-left: 1vw;
            margin-bottom: 10px;
        }

        .btn {
            margin-bottom: 10px;
        }


    }
</style>