<template>
    <div>
        <h3>Taken:</h3>

        <div v-if="taskCompleted">
            <b-list-group style="margin-top: 3vh;">
                <b-list-group-item class="d-flex justify-content-between align-items-center list" v-on:click="showCompleted = !showCompleted">
                    Voltooid
                    <b-badge variant="primary" pill>{{ taskCompleted.length }}</b-badge>
                </b-list-group-item>
                <div v-if="showCompleted">
                    <div class="grid">
                        <b-list-group-item variant="success" v-for="task in taskCompleted"
                                           :key="task.task"
                                           class="item">
                            {{task.task}}
                        </b-list-group-item>
                    </div>
                </div>

                <b-list-group-item class="d-flex justify-content-between align-items-center list" v-on:click="showUncompleted = !showUncompleted">
                    Onvoltooid
                    <b-badge variant="danger" pill>{{ taskUncompleted.length }}</b-badge>
                </b-list-group-item>
                <div v-if="showUncompleted" style="margin-bottom: 1vh">
                    <div class="grid">
                        <b-list-group-item variant="danger" v-for="task in taskUncompleted"
                                           :key="task.task"
                                           class="item">
                            {{task.task}}
                        </b-list-group-item>
                    </div>
                </div>
            </b-list-group>
        </div>

        <div v-if="!taskCompleted">
            nope
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminTask',
        data() {
            return {
                showCompleted: false,
                showUncompleted: false,
                showSucces: false,
            }
        },
        props: {
            taskCompleted: {
                type: Array,
            },
            taskUncompleted: {
                type: Array,
            },
        },
    }
</script>

<style scoped>
    .list {
         text-align: left;
        margin-top: 1vh;
        cursor: pointer;
        -webkit-filter: brightness(100%);
        color: black;
    }

        .list :hover {
            background-color: #000;
            opacity: 0.5;
        }

    .icon{
        max-height: 25px;
        position: absolute;
        right: 2vw;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 5fr);
    }

    .item {
        margin-left: 0vh !important;
        color: black;
    }

    h3 {
        text-align: left;
        padding: 1vh 0px 0px 10px
    }

    @media only screen and (max-width: 600px) {
        .list {
            max-width: 90vw;
        }
    }
</style>