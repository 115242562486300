<template>
    <div>
        <div v-if="classes != null">
            <h3>Lessen van die dag:</h3>
            <b-table :items="classes" :fields="fields" thead-class="hidden_header" fixed responsive="sm" class="custom-table">
                <template #cell(show)="row">
                    <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                        {{ row.detailsShowing ? 'Sluit' : 'Open'}} aanwezigheid
                    </b-button>
                </template>
                <template #row-details="row">
                    <b-card>
                        <b-row class="mb-2">
                            <b-col sm="3" class="text-sm-right"><b>Aantal personen:</b></b-col>
                            <b-col><b-form-input v-model="classPresence.presence" type="number"></b-form-input></b-col>
                        </b-row>
                        <b-button size="sm" @click="row.toggleDetails(); saveFunction(row.item.id)">Opslaan</b-button>
                    </b-card>
                </template>
            </b-table>
        </div>

        <div v-if="classes == null">
            <h2>Geen lessen die dag</h2>
            <p style="font-size: 6px; text-align:left; margin-left: 20px; margin-top:-15px;">So empty</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminClasses',
        data() {
            return {
                fields: ['name', 'time', 'instructor', 'presence'],
            }
        },
        props: {
            classes: {
                type: Array,
            },
        }
    }
</script>

<style scoped>
    h3 {
        text-align: left;
        padding: 1vh 0px 0px 10px
    }

    table {
        margin-top: 3vh;
    }
    
</style>

<style>
    .custom-table thead th {
        color: white !important;
    }

    .custom-table tbody td {
        color: white !important;
    }
</style>