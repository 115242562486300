<template>
    <div>
        <h3>Activiteiten:</h3>
        <p class="act" v-for="activity in activities" :key="activity.id"><b>{{activity.name}}:</b> {{activity.points}} punten</p>
    </div>
</template>

<script>
    export default {
        name: 'AdminActivity',
        data() {
            return {
                
            }
        },
        props: {
            activities: {
                type: Array,
            },
        }
    }
</script>

<style scoped>
    h3 {
        text-align: left;
        padding: 1vh 0px 0px 10px
    }

    .act{
        text-align: left;
        margin-left: 5vh;
    }
</style>