import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import AccountView from '../views/AccountView.vue'
import ShiftReport from '../views/ShiftReport.vue'
import AdminView from '../views/AdminView.vue'
import PrecenseView from '../views/PresenceView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView
    },
    {
        path: '/account',
        name: 'account',
        component: AccountView
    },
    {
        path: '/report',
        name: 'report',
        component: ShiftReport
    },
    {
        path: '/admin',
        name: 'admin',
        component: AdminView
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: () => import('../views/TasksView.vue')
    },
    {
        path: '/presence',
        name: 'presence',
        component: PrecenseView
    },
    {
        path: '/administration',
        name: 'administration',
        component: () => import('../views/AdministrationView.vue')
    },
    {
        path: '/clean',
        name: 'clean',
        component: () => import('../views/CleanView.vue')
    },
    {
        path: '/*',
        component: HomeView
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
