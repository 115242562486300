<template>
    <div class="space">
        <h1 class="title">Aanwezigheid doorgeven</h1>
        <br />

        <div>
            <b-row>
                <b-col>
                    <h3>Vandaag aanwezig (geweest):</h3>
                </b-col>
                <b-col cols="4">
                    <b-button v-b-toggle.sidebar-right variant="outline-secondary">Nieuw</b-button>
                </b-col>
            </b-row>

            <b-table hover :items="workers" :fields="fields" fixed class="presence" :key="update" stacked="sm">
                <template #cell(id)="data">
                    <b-button v-b-toggle.sidebar-edit variant="outline-secondary" @click="handleEditClick(data.value)">Aanpassen</b-button>
                </template>
            </b-table>
        </div>
        <br />
        <b-sidebar id="sidebar-right" right shadow no-header width="50em" backdrop-variant="dark" backdrop>
            <div class="px-3 py-2 detail">
                <label for="starttime">Naam:</label>
                <b-form-input v-model="name" placeholder="Vul je naam in" class="inputs"></b-form-input>
                <b-row>
                    <b-col>
                        <label for="starttime">Start tijd:</label>
                        <b-form-input id="starttime" v-model="starttime" type="time" class="inputs"></b-form-input>
                    </b-col>
                    <b-col>
                        <label for="endtime">Eind tijd:</label>
                        <b-form-input id="endtime" v-model="endtime" type="time" class="inputs"></b-form-input>
                    </b-col>
                </b-row>
                <b-button variant="secondary" block @click="save" class="inputs" v-b-toggle.sidebar-right>Opslaan</b-button>
            </div>
        </b-sidebar>
        <b-sidebar id="sidebar-edit" right shadow no-header width="50em" backdrop-variant="dark" backdrop>
            <div class="px-3 py-2 detail">
                <label for="starttime">Naam:</label>
                <b-form-input v-model="editWorker.name" placeholder="Vul je naam in" class="inputs"></b-form-input>
                <b-row>
                    <b-col>
                        <label for="starttime">Start tijd:</label>
                        <b-form-input id="starttime" v-model="editWorker.starttime" type="time" class="inputs"></b-form-input>
                    </b-col>
                    <b-col>
                        <label for="endtime">Eind tijd:</label>
                        <b-form-input id="endtime" v-model="editWorker.endtime" type="time" class="inputs"></b-form-input>
                    </b-col>
                </b-row>
                <b-button variant="secondary" block @click="updateData" class="inputs" v-b-toggle.sidebar-edit>Opslaan</b-button>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'PresenceView',
        data() {
            return {
                name: '',
                starttime: '',
                endtime: '',
                workers: [],
                fields: [
                    {
                    key: 'name',
                        label: 'Naam:',
                        sortable: true
                    },
                    {
                        key: 'date',
                        label: 'Datum:'
                    },
                    {
                        key: 'starttime',
                        label: 'Starttijd:',
                        sortable: false
                    },
                    {
                        key: 'endtime',
                        label: 'Eindtijd:',
                        sortable: false
                    },
                    {
                        key: 'id',
                        label: '',
                        sortable: false
                    }],
                update: 0,
                editId: 0,
                editWorker: [{
                    name: null,
                    starttime: null,
                    endtime: null,
                }],
            }
        },
        async mounted() {
            axios
                .get('https://teambm.azurewebsites.net/presence/get')
                .then(response => {
                    this.workers = response.data
                })
        },
        methods: {
            async save() {
                await axios
                    .get('https://teambm.azurewebsites.net/presence/save?name=' + this.name + '&starttime=' + this.starttime + '&endtime=' + this.endtime )
                    .then(response => {
                        this.details = response.data
                        this.update + 1
                    })
               
            },
            handleEditClick(id) {
                this.editId = id;
                const selectedWorker = this.workers.find(worker => worker.id === id);
                console.log(selectedWorker)
                this.editWorker = selectedWorker;
                console.log(this.editWorker)
            },
            updateData() {
                axios({
                    url: 'https://teambm.azurewebsites.net/admin/UpdatePresence',
                    method: 'post',
                    data: this.editWorker
                })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
        }
    }
</script>

<style scoped>
    .title {
        margin-top: 5vh;
        margin-left: 15vh;
    }
    .space {
        text-align: left;
        margin-left: 15vh;
    }
    h3{
        margin-left: 20vh;
    }
    .detail {
        text-align: left;
    }
    .presence {
        max-width: 90vw;
        margin-top: 5vh;
        margin-left: -5vh
    }
    .inputs{
        margin: 10px;
    }

    @media only screen and (max-width: 600px) {
        .title {
            padding: 20px 0 0 0;
            margin-left: 2vh;
            margin-top: 0vh;
            margin-top: 5vh;
        }
        .space {
            margin-left: 0vh;
        }
        h3 {
            margin-left: 5vh;
            font-size: 20px;
        }
        .presence {
            max-width: 95vw;
            margin-top: 5vh;
            margin-left: 2.5vw;
        }
    }
</style>