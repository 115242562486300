<template>
    <div class="overflow">
        <b-row>
            <b-col cols="8">
                <h1 class="title">Admin overzicht</h1>
            </b-col>
            <b-col class="picker">
                <b-form-datepicker id="datepicker-buttons"
                                   today-button
                                   locale="nl"
                                   :value="selectedDate"
                                   @input="handleDateChange"></b-form-datepicker>
            </b-col>
            <b-col class="column dropdownPicker">
                <b-dropdown :text="sector" variant="outline-dark" class="m-2">
                    <b-dropdown-item v-on:click="changeTime('open')">Openen</b-dropdown-item>
                    <b-dropdown-item v-on:click="changeTime('close')">Sluiten</b-dropdown-item>
                </b-dropdown>
            </b-col>
        </b-row>

        <div v-if="newTime">
            <h4>{{sector}}</h4>
            <AdminTop :key="key" :taskCompleted="taskCompleted" :taskUncompleted="taskUncompleted" :administrations="administrations" :presences="presences" />
            <AdminBottom :classes="classes" :activities="activities" :reports="reports" />
        </div>
        <div v-if="!newTime">
            <h4>Kies een tijd om verder te gaan</h4>
        </div>
               
    </div>
</template>

<script>
    import AdminTop from '../partials/admin/AdminTop.vue'
    import AdminBottom from '../partials/admin/AdminBottom.vue'
    import axios from 'axios'

    export default {
        name: 'HomeView',
        components: {
            AdminTop,
            AdminBottom
        },
        data() {
            return {
                selectedDate: new Date().toISOString().slice(0, 10),
                taskCompleted: [],
                taskUncompleted: [],
                classes: [],
                administrations: [],
                presences: null,
                activities: [],
                reports: [],
                key: 0,
                sector: 'Kies een andere tijd',
                newTime: '',
            }
        },
        methods: {
            handleDateChange(selectedDate) {
                this.selectedDate = selectedDate;
                axios
                    .get('https://teambm.azurewebsites.net/admin/GetAdmin?date=' + this.selectedDate + '&time=' + this.newTime)
                    .then(response => {
                        this.taskCompleted = response.data.tasksCompleted
                        this.taskUnCompleted = response.data.taskUncompleted
                        this.classes = response.data.classes
                        this.administrations = response.data.administrations
                        this.presences = response.data.presences
                        this.activities = response.data.activities
                        this.reports = response.data.reports
                    })

                this.key += 1;
            },
            changeTime(time) {
                switch (time) {
                    case 'open':
                        this.sector = "Openen";
                        if (this.currentTime <= 13) {
                            this.newTime = 9
                            break;
                        }
                        else {
                            this.newTime = 15
                            break;
                        }
                    case 'close':
                        this.sector = "Sluiten";
                        if (this.currentTime <= 13) {
                            this.newTime = 12
                            break;
                        }
                        else {
                            this.newTime = 21
                            break;
                        }
                    default:
                }
                this.handleDateChange(this.selectedDate);
            }
        },
        mounted() {
            axios
                .get('https://teambm.azurewebsites.net/admin/GetAdmin?date=' + this.selectedDate + '&time=' + this.currentTime)
                .then(response => {
                    console.log(response.data.tasksCompleted)
                    this.taskCompleted = response.data.tasksCompleted
                    this.taskUnCompleted = response.data.taskUncompleted
                    this.classes = response.data.classes
                    this.administrations = response.data.administrations
                    this.presences = response.data.presences
                    this.activities = response.data.activities
                })
        },
    }
</script>

<style scoped>
    .title {
        text-align: left;
        margin-left: 25vh;
        margin-top: 5vh;
    }

    h4 {
        text-align: left;
        margin-left: 35vh;
    }


    @media only screen and (max-width: 600px) {
        .title {
            margin-left: 3vh;
            margin-top: 5vh;
        }

        h4 {
            text-align: left;
            margin-left: 2vh;
        }

        .overflow{
            overflow-y: hidden;
        }
    }
</style>