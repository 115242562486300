<template>
    <div>
        <b-container fluid>
            <b-row class="boxGroup">
                <b-col class="box">
                    <AdminClasses :classes="classes"/>
                </b-col>
                <b-col class="box">
                    <AdminActivity :activities="activities"/>
                </b-col>
                <b-col class="box">
                    <AdminReport :reports="reports"/>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import AdminClasses from './AdminClasses.vue'
    import AdminActivity from './AdminActivity.vue'
    import AdminReport from './AdminReport.vue'
    export default {
        name: 'AdminBottom',
        components: {
            AdminClasses,
            AdminActivity,
            AdminReport,
        },
        data() {
            return {
            }
        },
        props: {
            classes: {
                type: Array,
            },
            activities: {
                type: Array
            },
            reports: {
                type: Array
            }
        },
    }
</script>

<style scoped>
    .boxGroup {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: flex; /* Display boxes in a row on desktop */
        flex-wrap: wrap; /* Wrap boxes to the next line on smaller screens */
        justify-content: center; /* Center boxes horizontally */
    }

    .box {
        flex: 1; /* Distribute available space evenly among boxes */
        margin-top: 5vh;
        background: rgb(71,43,162);
        background: linear-gradient(0deg, rgba(71,43,162,1) 0%, rgba(122,85,242,1) 100%);
        color: white;
        min-height: 200px;
        margin: 25px;
        border-radius: 10px;
    }

    h2 {
        text-align: left;
        padding: 20px 0px 0px 10px
    }

    @media only screen and (max-width: 600px) {
        .boxGroup {
            max-width: 95vw !important;
        }

        .box {
            margin-top: 1vw;
            flex: 0 0 100%; /* Full width on mobile, don't grow or shrink */
            margin-top: 2vh;
            margin-bottom: 15px;
        }
    }
</style>