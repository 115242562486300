<template>
    <div>
        <b-navbar class="bar navbar navbar-dark bg-dark" toggleable="lg" >

            <img src="../assets/logo.png" alt="Logo" class="navbar-brand logo"/>
            

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <div class="navbar-nav mr-auto">
                        <router-link to="/home" class="nav-item nav-link first">Home</router-link>
                        <router-link to="/report" class="nav-item nav-link" style="margin-left: 5vh;">Shiftklaar</router-link>
                        <router-link to="/tasks" class="nav-item nav-link" style="margin-left: 5vh;">Taken</router-link>
                        <router-link to="/clean" class="nav-item nav-link" style="margin-left: 5vh;">Schoonmaken</router-link>
                        <router-link to="/presence" class="nav-item nav-link" style="margin-left: 5vh;">Aanwezigheid</router-link>
                        <router-link to="/administration" class="nav-item nav-link" style="margin-left: 5vh;">Administratie</router-link>
                        <router-link to="/admin" class="nav-item nav-link" style="margin-left: 5vh;" v-if="isAdmin">Admin</router-link>
                    </div>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-form>
                        <router-link to="/account" class="mr-sm-2 nav-item nav-link">Account</router-link>
                    </b-nav-form>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        name: 'NavView',
        data() {
            return {
                isAdmin: false,
            }
        },
        mounted() {
            const name = localStorage.getItem('name');
            this.isAdmin = name === 'Admin';
        },
    }
</script>

<style lang="scss" scoped>
    nav {
        background-color: #181818 !important;

        a {
            font-weight: normal;
            color: white !important;
            margin-left: 50px !important;
            font-size: 18px;
            text-align: left;

            &.router-link-exact-active {
                color: #7A55F3 !important;
                font: bold;
                text-decoration: underline;
            }
        }
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Hamburger_icon_white.svg/1024px-Hamburger_icon_white.svg.png) !important;
    }

    .logo {
        margin-left: 40px;
        position: absolute;
        height: 175px !important;
        top: 0;
    }

    .bar {
        -webkit-box-shadow: 0px 9px 15px 0px rgba(0,0,0,0.77);
        box-shadow: 0px 9px 15px 0px rgba(0,0,0,0.77);
    }

    .first{
        margin-left: 25vh !important;
    }

    @media only screen and (max-width: 600px) {
        .logo{
            margin-left: 60vw;
            height: 150px !important;
        }

        .first {
            margin-left: 5vh !important;
        }
    }
</style>