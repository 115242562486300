<template>
    <div class="body">
        <div class="gradient"></div>
        <b-alert show variant="danger" v-if="this.show == true" class="alert">{{this.alert}}</b-alert>
        <img src="../assets/logo.png" alt="Logo" class="logo" />
        <div class="containerLogin">
            <div class="input-group">
                <input required="" type="text" name="text" class="input" v-model="username">
                <label class="user-label">Gebruikersnaam</label>
            </div>
            <div class="input-group">
                <input required="" type="password" name="text" class="input" v-model="password">
                <label class="user-label">Wachtwoord</label>
            </div>
        </div>
        <b-button class="btn" variant="outline-light" v-on:click="login" type="submit" v-if="!clicked">Login</b-button>
        <div v-if="clicked">
            <b-row style="position: fixed; top: 65%; left: 45%;">
                <b-col>
                    <h5 style="color:white; margin-top: 25px;">Inloggen </h5>
                </b-col>
                <b-col>
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'LoginView',
        data() {
            return {
                correct: false,
                username: '',
                password: '',
                alert: 'Onjuiste gegevens',
                show: false,
                clicked: false
            }
        },
        methods: {
            login() {
                //create a check that only allows to get the admin view, through the backend
                localStorage.setItem("name", this.username)
                axios
                    .get('https://teambm.azurewebsites.net/user/login?' + "username=" + this.username + "&password=" + this.password)
                    .then(response => {
                        this.clicked = true
                        if (response.data) {
                            localStorage.setItem("id", response.data)
                            this.$router.push({ path: '/home', replace: true })
                        }
                        else {
                            this.show = true
                            this.clicked = false
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    });

            }
        }
    }
</script>

<style lang="scss" scoped>
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .gradient {
        --size: 700px;
        --speed: 30s;
        --easing: cubic-bezier(0.8, 0.4, 0.4, 0.8);
        width: var(--size);
        height: var(--size);
        filter: blur(calc(var(--size) / 5));
        background-image: linear-gradient(hsl(272, 39, 49, 60%), hsl(254, 64, 53));
        animation: rotate var(--speed) var(--easing) alternate infinite;
        border-radius: 80% 90% 30% 30% / 80% 90% 80% 70%;
        position: absolute;
    }

    .logo {
        color: white;
        position: absolute;
        max-height: 250px;
        top: 20vh;
    }

    .btn {
        margin-top: 300px !important;
        position: fixed
    }

    .alert {
        position: absolute;
        margin-top: -35%;
    }

    @media (max-width: 720px) {
        .gradient {
            --size: 500px;
            --speed: 30s;
            --easing: cubic-bezier(0.8, 0.4, 0.4, 0.8);
            width: var(--size);
            height: var(--size);
            filter: blur(calc(var(--size) / 5));
            background-image: linear-gradient(hsl(272, 39, 49, 60%), hsl(254, 64, 53));
            animation: rotate var(--speed) var(--easing) alternate infinite;
            border-radius: 80% 90% 30% 30% / 80% 90% 80% 70%;
        }
    }

    .body {
        background-color: #222;
        position: absolute;
        inset: 0px 0px 0px 0px;
        display: flex;
        place-content: center;
        align-items: center;
        overflow: hidden;
    }

    /* This is just to transition when you change the viewport size. */
    * {
        transition: all 0.25s ease-out;
    }

    @media only screen and (max-width: 600px) {
        .logo {
            position: absolute;
            max-height: 200px;
            top: 10vh;
        }

        .btn {
            margin-top: 300px !important;
        }
    }
</style>

<style scoped>
    .input-group {
        margin-bottom: 1vh;
        top: 5vh;
    }

    .input {
        border: solid 1.5px #9e9e9e;
        border-radius: 1rem;
        background: none;
        padding: 1rem;
        font-size: 1rem;
        color: white;
        transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .user-label {
        position: absolute;
        top: 1rem;
        left: 1rem;
        color: white;
        pointer-events: none;
        transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .input:focus,
    input:valid {
        outline: none;
        border: 1.5px solid #727272;
    }

        .input:focus ~ .user-label,
        input:valid ~ .user-label {
            transform: translateY(-80%) scale(0.8);
            padding: 0 .2em;
            color: #CECFC7;
        }

    .containerLogin {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-background-clip: border-box;
        -webkit-text-fill-color: #ffffff;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px #23232329;
    }
</style>

<!--css loader-->
<style>
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 50px;
        height: 50px;
    }

        .lds-ellipsis div {
            position: absolute;
            top: 33px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: #fff;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
        }

            .lds-ellipsis div:nth-child(1) {
                left: 8px;
                animation: lds-ellipsis1 0.6s infinite;
            }

            .lds-ellipsis div:nth-child(2) {
                left: 8px;
                animation: lds-ellipsis2 0.6s infinite;
            }

            .lds-ellipsis div:nth-child(3) {
                left: 32px;
                animation: lds-ellipsis2 0.6s infinite;
            }

            .lds-ellipsis div:nth-child(4) {
                left: 56px;
                animation: lds-ellipsis3 0.6s infinite;
            }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(0);
        }
    }

    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(24px, 0);
        }
    }
</style>
