<template>
    <div>
        <h3>Aanwezigheid:</h3>
        <b-table hover :items="presences" :fields="fields" fixed class="presence" stacked="sm" v-b-toggle.sidebar-right></b-table>

        <b-sidebar id="sidebar-right" title="Tijd aanpassen" right shadow backdrop-variant="dark" backdrop width="30vw">
            <div class="px-3 py-2">
                <b-list-group class="list">
                    <b-list-group-item class="shift" v-for="presence in presences" :key="presence.id">
                        <h5>{{presence.name}}</h5>
                        <b-row class="context">
                            <b-form-input class="input" v-model="presence.starttime"></b-form-input>
                            <b-form-input class="input" v-model="presence.endtime"></b-form-input>
                        </b-row>
                        <p>Totaal: {{presence.hours}} uur</p>
                        <b-button @click="save(presence)" v-b-toggle.sidebar-right>Opslaan</b-button>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'AdminPresence',
        components: {
        },
        data() {
            return {
                fields: [
                    {
                        key: 'name',
                        label: 'Naam:',
                        sortable: true
                    },
                    {
                        key: 'date',
                        label: 'Datum:'
                    },
                    {
                        key: 'starttime',
                        label: 'Starttijd:',
                        sortable: true
                    },
                    {
                        key: 'endtime',
                        label: 'Eindtijd:',
                        sortable: true
                    }],
            }
        },
        props: {
            presences: {
                type: Array,
            },
        },
        methods: {
            save(presence) {
                axios({
                    url: 'https://teambm.azurewebsites.net/admin/UpdatePresence',
                    method: 'post',
                    data: presence
                })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    }
</script>

<style scoped>  
    .presence {
        margin-top: 3vh;
        color: white;
    }

    h3 {
        text-align: left;
        padding: 1vh 0px 0px 10px
    }

    .shift {
        transition: all .2s ease-in-out;
    }

        .shift:hover {
            transform: scale(1.05);
        }

    .list {
        color: black;
    }

    .context {
        margin-left: 7vw;
    }

    .input{
        max-width: 80px;
        margin-left: 1vw;
    }

    @media only screen and (max-width: 600px) {
        .context {
            margin-left: 10px;
        }
    }
</style>